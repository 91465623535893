import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import ReactPlayer from "react-player"
import Spot from '../../spotlight.png'; 


const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  title: {
    fontSize: '1.04rem',
    fontStyle: 'italic',
    lineHeight: '1.4rem',
  },
  smalltitle: {
    fontSize: '0.90rem',
    lineHeight: '1.4rem',
    //fontWeight: 'bold',
    //fontStyle: 'italic',
  },
  vidPlayer: {
    backgroundColor: 'black',
    
  },
});

export default function ProfileImg() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>

      {/* <CardContent>         
        <Typography className={classes.smalltitle}>ACTOR</Typography> 
      </CardContent>  */}
      
      <CardMedia>
        <ReactPlayer className={classes.vidPlayer} url="https://player.vimeo.com/video/1039761142" width="640" controls="true" />
      </CardMedia>     

      <CardContent>    
        <Typography className={classes.title}>'Robin, I want you to be in this movie'</Typography>     
        <Typography className={classes.smalltitle}>-Christopher McQuarrie</Typography> 
      </CardContent> 

      </CardActionArea>     
    </Card>
 

    

    
  );
}


